import React from 'react'

import { Link } from 'gatsby'
import Img from 'gatsby-image'

import styled from 'styled-components'

import Layout from '../../components/layout'

import { MainSectionWrapper } from '../../components/common/styles'

const MainSectionWrapperOverride = styled(MainSectionWrapper)`
    grid-row: 3 / 5;
    
    width: 75%;
    padding: 0;
`

const CeramicsSectionPhotoWrapper = styled.div`
    width: 44%;

    display: grid;
    place-items: center;

    & img {
        margin-bottom: 0;
    }

    & a {
        width: 100%;
    }
`

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transition: .5s ease;
  background-color: ${props => `rgba(${props.overlayColor.r}, ${props.overlayColor.g}, ${props.overlayColor.b}, 0)`};

  display: flex;
  justify-content: center;
  align-items: center;

  color: transparent;

  z-index: 2;

  &:hover {
      background-color: ${props => `rgba(${props.overlayColor.r}, ${props.overlayColor.g}, ${props.overlayColor.b}, ${props.transparency})`};

      color: ${props => props.textColor};
  }
`

const CeramicsPage = ({ data }) => (
    <Layout>
        <MainSectionWrapperOverride>
            <div style={{ display: 'flex', flexWrap: 'wrap', height: '100%', justifyContent: 'space-around', overflow: 'hidden' }}>
                <CeramicsSectionPhotoWrapper>
                    <Link to="/artisans_and_world/ecuador">
                        <div style={{ position: 'relative', cursor: 'pointer' }}>
                            <ImageOverlay textColor='white' overlayColor={{ r: 0, g: 255, b: 255 }} transparency={0.40}>
                                <div style={{ fontSize: '48px', fontWeight: 700 }}>
                                    Ecuador 2018
                                </div>
                            </ImageOverlay>
                            <Img style={{ maxHeight: '300px' }} fluid={data.ecuador.childImageSharp.fluid} />
                        </div>
                    </Link>
                </CeramicsSectionPhotoWrapper>
                <CeramicsSectionPhotoWrapper>
                    <Link to="/artisans_and_world/haiti">
                        <div style={{ position: 'relative', cursor: 'pointer' }}>
                            <ImageOverlay textColor='white' overlayColor={{ r: 0, g: 255, b: 255 }} transparency={0.30}>
                                <div style={{ fontSize: '48px', fontWeight: 700 }}>
                                    Haiti 2017
                            </div>
                            </ImageOverlay>
                            <Img style={{ maxHeight: '300px' }} fluid={data.haiti.childImageSharp.fluid} />
                        </div>
                    </Link>
                </CeramicsSectionPhotoWrapper>
                <CeramicsSectionPhotoWrapper>
                    <Link to="/artisans_and_world/colombia">
                        <div style={{ position: 'relative', cursor: 'pointer' }}>
                            <ImageOverlay textColor='white' overlayColor={{ r: 0, g: 255, b: 255 }} transparency={0.30}>
                                <div style={{ fontSize: '48px', fontWeight: 700 }}>
                                    Colombia 2016
                            </div>
                            </ImageOverlay>
                            <Img style={{ maxHeight: '300px' }} fluid={data.colombia.childImageSharp.fluid} />
                        </div>
                    </Link>
                </CeramicsSectionPhotoWrapper>
                <CeramicsSectionPhotoWrapper>
                    <Link to="/artisans_and_world/myanmar">
                        <div style={{ position: 'relative', cursor: 'pointer' }}>
                            <ImageOverlay textColor='white' overlayColor={{ r: 0, g: 255, b: 255 }} transparency={0.30}>
                                <div style={{ fontSize: '48px', fontWeight: 700 }}>
                                    Myanmar 2016
                            </div>
                            </ImageOverlay>
                            <Img style={{ maxHeight: '300px' }} fluid={data.myanmar.childImageSharp.fluid} />
                        </div>
                    </Link>
                </CeramicsSectionPhotoWrapper>
            </div>
        </MainSectionWrapperOverride>
    </Layout>
)

export default CeramicsPage

export const query = graphql`
  query {
        ecuador: file(relativePath: {eq: "images/artisans_and_world/ecuador/Ecuador1.jpg" }) {
            ...image
        }
        haiti: file(relativePath: {eq: "images/artisans_and_world/haiti/Haiti1.jpg" }) {
            ...image
        }
        colombia: file(relativePath: {eq: "images/artisans_and_world/colombia/IMG_7433.jpg" }) {
            ...image
        }
        myanmar: file(relativePath: {eq: "images/artisans_and_world/myanmar/1.jpg" }) {
            ...image
        }
        }
      `
